import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import Linkify from 'react-linkify';
import { makeStyles } from '@mui/styles';
import { classNames } from '../utils';

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: props => (props.center ? 'center' : 'left'),
    fontSize: props => {
      switch (props.size) {
        case 'small':
          return '0.875rem';
        default:
        case 'medium':
          return theme.font?.size ? theme.font.size?.text : '1rem';
        case 'large':
          return '1rem';
      }
    },
    fontWeight: props => (props.bold ? '600' : 'normal'),
    hyphenateCharacter: 'auto',
    hyphens: 'auto',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    color: props => {
      switch (props.color) {
        default:
        case 'default':
          return theme.color.text.main;
        case 'light':
          return theme.color.text.light;
        case 'dark':
          return theme.color.text.dark;
        case 'error':
          return theme.color.error.main;
        case 'primary':
          return theme.color.primary.contrastText;
        case 'secondary':
          return theme.color.primary.contrastText;
      }
    },
    ...(theme.components?.text?.root ? theme.components?.text?.root : {})
  }
}));

export default function Text(props) {
  const classes = useStyles(props);

  const forwardedProps = { ...props };
  delete forwardedProps.bold;
  delete forwardedProps.paragraph;

  // use ...props to make sure '@mui/core/Box' props get applied
  return props.paragraph ? (
    <p
      className={classNames([classes.text, props.className])}
      data-testid={props['data-testid']}
    >
      {props.children}
    </p>
  ) : (
    <Box
      {...forwardedProps}
      className={classNames([classes.text, props.className, 'text'])}
      center={undefined}
      color={undefined}
    >
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a
            target="blank"
            rel="noopener noreferrer"
            href={decoratedHref}
            key={key}
          >
            {decoratedText}
          </a>
        )}
      >
        {props.children}
      </Linkify>
    </Box>
  );
}

Text.propTypes = {
  color: PropTypes.oneOf(['default', 'dark', 'primary', 'light', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.node,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  center: PropTypes.bool,
  bold: PropTypes.bool,
  paragraph: PropTypes.bool
};

Text.defaultProps = {
  children: [],
  color: 'default',
  size: 'medium',
  center: false,
  'data-testid': undefined,
  bold: false,
  paragraph: false
};
