import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Field, useField, useForm } from 'react-final-form';
import { makeStyles, useTheme } from '@mui/styles';

import Button from './Button';
import Label from './Label';

import classNames from '../utils/react/classNames';
import isEmpty from '../utils/object/isEmpty';
import { required as requiredValidator } from '../utils/form/validators';

const useStyles = makeStyles(theme => ({
  formControl: {
    ...(theme.components?.multiCreate?.root
      ? theme.components?.multiCreate?.root
      : {})
  },
  itemList: {
    padding: 0,
    margin: 0
  },
  item: {
    listStyleType: 'none',
    border: `1px solid ${theme.color.border.main}`,
    borderRadius: theme.borderRadius.small,
    marginTop: theme.spacing(1),
    color: props =>
      props.disabled ? theme.color.text.light : theme.color.text.main,
    ...(theme.components?.multiCreate?.item
      ? theme.components?.multiCreate?.item
      : {})
  },
  selected: {}
}));

export default function MultiCreate(props) {
  const classes = useStyles(props);
  const [selected, setSelected] = useState(props.selectedItem);
  const [formVisible, setFormVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const field = useField(`${props.id}.values`);
  const form = useForm();
  const [fields, setFields] = useState(getFields());
  const theme = useTheme();

  function getFields() {
    const fs = [];
    const formFields = form.getRegisteredFields();

    // First get all Fields of sub form
    // And focus/blur to trigger field level/required validation
    for (let i = 0; i < formFields.length; i += 1) {
      if (
        formFields[i].startsWith(`${props.id}.`) &&
        formFields[i] !== `${props.id}.values`
      ) {
        fs.push(formFields[i]);
      }
    }

    return fs;
  }

  function validate() {
    const formState = form.getState();

    // Focus/blur to trigger field level/required validation
    for (let i = 0; i < fields.length; i += 1) {
      form.focus(fields[i]);
      form.blur(fields[i]);
    }

    // Now check for errors. Its enough to check for parrent object
    const errors = { ...formState.errors[props.id] };

    if (isEmpty(errors)) {
      return true;
    }

    const errorFieldKeys = Object.keys(errors);

    // Scroll to error
    if (errorFieldKeys?.length) {
      const errorField = document.getElementById(
        `${props.id}.${errorFieldKeys[0]}`
      );

      const scrollTop =
        errorField?.getBoundingClientRect().top -
        document.body?.getBoundingClientRect().top -
        2 * (theme.contentOffset ? parseInt(theme.contentOffset, 10) : 0);

      window?.scrollTo({
        top: scrollTop,
        left: 0,
        behavior: 'smooth'
      });
    }

    return false;
  }

  function getValue() {
    const formState = form.getState();

    // Split dot object notation into object
    const value = {
      ...(props.id.split('.').reduce((o, i) => o[i], formState?.values) || {})
    };
    delete value.values;

    // In edit mode mark entries that change as dirty
    if (props.edit) {
      value.dirty = true;
    }

    return value;
  }

  function addValue(value) {
    return [...field.input.value, value];
  }

  function saveValue(value) {
    const updatedValues = [...field.input.value];

    for (let i = 0; i < field.input.value.length; i += 1) {
      if (props.isOptionEqualToValue(field.input.value[i], selected)) {
        updatedValues[i] = { ...selected, ...value };
      }
    }

    return updatedValues;
  }

  function handleDiscard() {
    for (let i = 0; i < fields.length; i += 1) {
      form.change(fields[i], undefined);
      form.resetFieldState(fields[i]);
    }

    setSelected();
    setFormVisible(false);
    props.onSelectItemChange();

    if (props.onFormClose) props.onFormClose();
  }

  function handleSave() {
    handleFocus();
    handleBlur();

    if (!validate()) {
      return;
    }

    // First get all values with id prefix
    const value = getValue();

    // Save value
    field.input.onChange(
      saveValue(props.beforeSave ? props.beforeSave(value) : value)
    );

    // Reset all values with id prefix
    handleDiscard();

    if (props.onFormClose) props.onFormClose();
  }

  function handleDelete() {
    handleFocus();
    const updatedValues = [...field.input.value];

    for (let i = 0; i < field.input.value.length; i += 1) {
      if (props.isOptionEqualToValue(field.input.value[i], selected)) {
        // In edit mode use soft delete
        if (props.edit) {
          updatedValues[i].deleted = true;
        } else {
          updatedValues.splice(i, 1);
        }

        field.input.onChange(updatedValues);

        handleBlur();
        handleDiscard();
        return;
      }
    }
  }

  function handleAdd() {
    handleFocus();
    handleBlur();

    if (!validate()) {
      return;
    }

    // First get all values with id prefix
    const value = getValue();

    // Set value
    field.input.onChange(
      addValue(props.beforeAdd ? props.beforeAdd(value) : value)
    );

    // Reset all values with id prefix
    handleDiscard();

    if (props.onFormClose) props.onFormClose();
  }

  function handleSelect(item) {
    if (!props.disabled) {
      setLoading(true);
      handleFocus();

      setSelected(item);
      setFormVisible(true);

      setTimeout(() => {
        Object.keys(item).forEach(key => {
          if (form.getRegisteredFields().includes(`${props.id}.${key}`))
            form.change(`${props.id}.${key}`, item[key]);
        });
      });

      // Make sure loading runs after above async form.change
      setTimeout(() => {
        setLoading(false);
      }, 10);

      handleBlur();

      props.onSelectItemChange(item);
    }
  }

  useEffect(() => {
    if (props.selectedItem !== selected) {
      if (props.selectedItem) {
        handleSelect(props.selectedItem);
      } else {
        handleDiscard();
      }
    }
  }, [props.selectedItem]);

  useEffect(() => {
    if (formVisible === true && props.onFormOpen) {
      setFields(getFields());
      props.onFormOpen(form);
    }
  }, [formVisible]);

  function handleBlur() {
    field.input.onBlur();

    if (props.onBlur) props.onBlur();
  }

  function handleFocus() {
    field.input.onFocus();

    if (props.onFocus) props.onFocus();
  }

  return (
    <Field
      name={`${props.id}.values`}
      validateFields={props.validateFields || []}
      validate={(value, allValues, meta) => {
        const v = selected ? saveValue(getValue()) : addValue(getValue());

        let required;
        if (props.required) {
          let items = v;

          if (props.edit && items?.length) {
            items = items.filter(item => !item.deleted);
          }

          required = requiredValidator(items, {
            customMessage: props.intl?.required
          });
        }
        if (required) return required;

        if (props.validate) return props.validate(v, allValues, meta);

        return undefined;
      }}
    >
      {({ meta, input }) => {
        const error =
          props.error || ((meta.error || meta.submitError) && meta.touched);
        const errorText =
          props.errorText ||
          (meta.touched ? meta.error || meta.submitError : '');
        const value = Array.isArray(input.value)
          ? input.value.sort((a, b) => a.order - b.order)
          : [];
        setFormVisible((formVisible || value.length <= 0) && !props.disabled);

        return (
          <FormControl
            className={classes.formControl}
            fullWidth
            error={error}
            disabled={props.disabled}
          >
            {props.label && (
              <Label
                for={props.id}
                disabled={props.disabled}
                required={props.required && !props.requiredWithoutAsterisk}
                popover={{
                  title: props.popoverTitle,
                  text: props.popoverText
                }}
              >
                {props.label}
              </Label>
            )}
            <Box id={`${props.id}.values`}>
              <ul className={classes.itemList}>
                {value.map((item, i) => {
                  const isSelected = props.isOptionEqualToValue(
                    field.input.value[i],
                    selected
                  );

                  return (
                    // Only show items that are not soft deleted
                    !props.edit || (props.edit && !item.deleted) ? (
                      <li
                        tabIndex={0}
                        key={i}
                        className={classNames([
                          classes.item,
                          isSelected ? classes.selected : null
                        ])}
                      >
                        {!isSelected || (isSelected && loading)
                          ? props.preview(item, {
                              handleSelect,
                              isSelected,
                              index: i
                            })
                          : null}
                        {isSelected ? (
                          <Box
                            sx={{
                              visibility: loading ? 'hidden' : 'visible',
                              height: loading ? 0 : 'auto'
                            }}
                          >
                            {props.form}
                            {(errorText || props.helperText) && (
                              <Box
                                px={2}
                                pb={1}
                                display="flex"
                                justifyContent="flex-end"
                              >
                                <FormHelperText
                                  id={`${props.id}-helper-text`}
                                  style={{ margin: 0 }}
                                  aria-label={errorText || props.helperText}
                                  error={props.error}
                                >
                                  {errorText || props.helperText}
                                </FormHelperText>
                              </Box>
                            )}
                            <Box
                              px={2}
                              pb={2}
                              gap={0.5}
                              display="flex"
                              justifyContent="flex-end"
                              flexWrap="wrap"
                            >
                              <Button
                                variant="outlined"
                                icon
                                size="small"
                                data-testid={`multicreate-${props.id}-delete-btn`}
                                color="secondary"
                                title={props.intl?.delete}
                                disabled={props.disabled}
                                onClick={handleDelete}
                              >
                                delete
                              </Button>
                              <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                data-testid={`multicreate-${props.id}-discard-btn`}
                                disabled={props.disabled}
                                onClick={handleDiscard}
                              >
                                {props.intl?.discard}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                data-testid={`multicreate-${props.id}-save-btn`}
                                onClick={handleSave}
                                disabled={props.disabled}
                              >
                                {props.intl?.save}
                              </Button>
                            </Box>
                          </Box>
                        ) : null}
                      </li>
                    ) : null
                  );
                })}
              </ul>
              {formVisible && !selected ? (
                <Box className={classes.item}>
                  {props.form}
                  {(errorText || props.helperText) && (
                    <Box px={2} pb={1} display="flex" justifyContent="flex-end">
                      <FormHelperText
                        style={{ margin: 0 }}
                        id={`${props.id}-helper-text`}
                      >
                        {errorText || props.helperText}
                      </FormHelperText>
                    </Box>
                  )}
                  <Box
                    px={2}
                    pb={2}
                    gap={0.5}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    flexWrap="wrap"
                  >
                    {value.length > 0 && (
                      <Button
                        variant="outlined"
                        data-testid={`multicreate-${props.id}-discard-btn`}
                        color="primary"
                        size="small"
                        onClick={handleDiscard}
                        disabled={props.disabled}
                      >
                        {props.intl?.discard}
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      data-testid={`multicreate-${props.id}-add-btn`}
                      size="small"
                      onClick={handleAdd}
                      disabled={props.disabled}
                    >
                      {props.intl?.add}
                    </Button>
                  </Box>
                </Box>
              ) : null}
              {(!selected && !formVisible) || (selected && loading) ? (
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon="add"
                    data-testid={`multicreate-${props.id}-add-entry-btn`}
                    disabled={props.disabled}
                    onClick={() => setFormVisible(true)}
                  >
                    {props.intl?.addEntry}
                  </Button>
                </Box>
              ) : null}
            </Box>
          </FormControl>
        );
      }}
    </Field>
  );
}

MultiCreate.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.node.isRequired,
  preview: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func,
  selectedItem: PropTypes.object,
  onSelectItemChange: PropTypes.func,
  beforeSave: PropTypes.func,
  beforeAdd: PropTypes.func,
  onFormOpen: PropTypes.func,
  error: PropTypes.bool,
  edit: PropTypes.bool,
  validate: PropTypes.func,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.node,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onFormClose: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  requiredWithoutAsterisk: PropTypes.bool,
  intl: PropTypes.shape({
    add: PropTypes.string,
    addEntry: PropTypes.string,
    delete: PropTypes.string,
    discard: PropTypes.string,
    save: PropTypes.string
  })
};

MultiCreate.defaultProps = {
  error: false,
  label: undefined,
  edit: false,
  selectedItem: undefined,
  errorText: undefined,
  isOptionEqualToValue: (value, selected) =>
    JSON.stringify(value) === JSON.stringify(selected),
  helperText: undefined,
  onChange: () => {},
  onFormOpen: () => {},
  validate: undefined,
  onBlur: () => {},
  onSelectItemChange: () => {},
  onFocus: () => {},
  disabled: false,
  required: false,
  requiredWithoutAsterisk: false,
  intl: {
    add: 'Hinzufügen',
    addEntry: 'Eintrag hinzufügen',
    delete: 'Löschen',
    discard: 'Verwerfen',
    save: 'Speichern'
  }
};
